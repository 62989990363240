import React from 'react';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { showDepositDialog, showWithdrawDialog } from '../../actions/home';
import { commaSeparator, splitDecimals } from '../../utils/numbers';
import NetworkImages from '../../components/NetworkImages';
import DotsLoading from '../../components/DotsLoading';

const DepositCard = (props) => {
    const denom = props.project && props.project.price && props.project.price.denom;
    const ibcToken = denom && props.ibcTokensList && props.ibcTokensList.length &&
        props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
    const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals;
    const displayDenom = ibcToken && ibcToken.network && ibcToken.network.display_denom;

    let balance = props.balance && props.balance.length && props.balance.find((val) => val.denom === denom);
    const disable = !balance || balance < 0;
    balance = balance && balance.amount && splitDecimals(balance.amount / (10 ** decimals));

    return (
        <div className="deposit_card">
            <div className="header">
                <h2>{variables[props.lang]['deposit_atom1'] + ` ${displayDenom} ` + variables[props.lang]['deposit_atom2']}</h2>
            </div>
            <p className="content">
                {variables[props.lang]['deposit_atom_content1'] + ` ${displayDenom} ` + variables[props.lang]['deposit_atom_content2']}
            </p>
            <div className="amount">
                <NetworkImages
                    alt={ibcToken && ibcToken.network && ibcToken.network.display_denom}
                    name={ibcToken && ibcToken.network && ibcToken.network.display_denom}/>
                <span>Available {ibcToken && ibcToken.network && ibcToken.network.display_denom}</span>
                {props.balanceInProgress
                    ? <DotsLoading/>
                    : balance && balance.length
                        ? <p>
                            {balance.length && balance[0] && commaSeparator(balance[0])}
                            {balance.length && balance[1] &&
                            <span>.{balance.length && balance[1]}</span>}
                        </p>
                        : <p>0</p>}
            </div>
            <div className="actions">
                <Button
                    disabled={props.address === ''}
                    variant="contained"
                    onClick={props.showDepositDialog}>
                    {variables[props.lang].deposit}
                </Button>
                <Button
                    disabled={disable}
                    variant="contained"
                    onClick={props.showWithdrawDialog}>
                    {variables[props.lang].withdraw}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => window.open('https://go.skip.build')}>
                    {variables[props.lang].ibc_swap}
                </Button>
            </div>
        </div>
    );
};

DepositCard.propTypes = {
    address: PropTypes.string.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    showDepositDialog: PropTypes.func.isRequired,
    showWithdrawDialog: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        lang: state.language,
        project: state.mint.project.value,
    };
};

const actionToProps = {
    showDepositDialog,
    showWithdrawDialog,
};

export default connect(stateToProps, actionToProps)(DepositCard);
