import React, { Component } from 'react';
import './index.css';
import TextField from '../../components/TextField';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../components/WithRouter';
import { setPricePerNFT } from '../../actions/home';
import NetworkImages from '../../components/NetworkImages';
import DotsLoading from '../../components/DotsLoading';

class PricePerNFTTextField extends Component {
    constructor (props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (value) {
        this.props.setPricePerNFT(value);
    }

    render () {
        const denom = this.props.project && this.props.project.price && this.props.project.price.denom;
        const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
            this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));

        return (
            <div className="price_per_nft_textfield">
                {this.props.projectInProgress
                    ? <DotsLoading/>
                    : <TextField
                        disable
                        id="price"
                        inputProps={{
                            startAdornment: (
                                <div>
                                    <NetworkImages
                                        alt={ibcToken && ibcToken.network && ibcToken.network.display_denom}
                                        name={ibcToken && ibcToken.network && ibcToken.network.display_denom}/>
                                </div>
                            ),
                            endAdornment: (
                                <div>
                                    {ibcToken && ibcToken.network && ibcToken.network.display_denom}
                                </div>
                            ),
                        }}
                        name="price"
                        value={this.props.value}
                        onChange={this.handleChange}/>}
            </div>
        );
    }
}

PricePerNFTTextField.propTypes = {
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    projectInProgress: PropTypes.bool.isRequired,
    setPricePerNFT: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
};

const stateToProps = (state) => {
    return {
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        lang: state.language,
        project: state.mint.project.value,
        projectInProgress: state.mint.project.inProgress,
        value: state.home.pricePerNFTValue,
    };
};

const actionToProps = {
    setPricePerNFT,
};

export default withRouter(connect(stateToProps, actionToProps)(PricePerNFTTextField));
